<template>
  <SignInLayout :title="this.$t('login.title')" :subTitle="this.$t('login.subtitle', { brandName })">
    <SignIn />
  </SignInLayout>
</template>

<script>
import SignInLayout from "@/components/SignInLayout";
import SignIn from "@/components/SignInLayout/SignIn";
export default {
  name: "signInView",
  components: {
    SignInLayout,
    SignIn,
  },
  data() {
    return {
      brandName: process.env.VUE_APP_BRAND_NAME,
    };
  },
};
</script>
