<template>
  <div class="login_card">
    <div>
      <v-tabs background-color="transparent" class="login_methods">
        <v-tab class="login_methods__tab" @click="method = 'email'">Email</v-tab>
        <v-tab class="login_methods__tab" @click="method = 'phone'">Phone</v-tab>
      </v-tabs>
      <div v-if="method === 'email'">
        <BaseInput
          v-if="this.step === 1 || this.method === 'email'"
          v-model="email"
          :errors="emailErrors"
          :fullWidth="true"
          :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
          :hide-details="true"
          fieldName="email"
          outlined
          placeholder="Enter your email"
          required
          @blur="$v.email.$touch()"
          @change="onEmailChange"
        />
        <PasswordInput
          v-model="password"
          :errors="passwordErrors"
          :fullWidth="true"
          :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
          :placeholder="$t('general.inputs.password')"
          fieldName="password"
          @blur="$v.password.$touch()"
          @change="onInputChange"
        />
        <PrimaryButton
          v-if="this.method === 'email'"
          :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
          :loading="loading"
          :text="$t('login.button')"
          class="login-process-btn"
          size="x-large"
          @onClick="submitEmailAuth"
        />
      </div>
      <div v-if="method === 'phone'">
        <PhoneInput
          v-if="this.step === 1"
          v-model="phoneNumber"
          :errors="phoneNumberErrors"
          :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
          placeholder="Phone Number"
          @blur="$v.phoneNumber.$touch()"
          @change="phoneNumberChange"
        />
        <div v-if="step === 2" class="code">
          <input
            v-for="(cell, index) in 6"
            :key="index"
            :index="index"
            class="code-cell"
            maxlength="1"
            @input="onCodeInput"
          />
        </div>
        <PrimaryButton
          v-if="step === 1"
          :disabled="!isNextEnabled"
          :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
          :loading="loading"
          class="login-process-btn"
          size="x-large"
          text="Next"
          @onClick="submitFirstStep"
        />
        <PrimaryButton
          v-if="this.method === 'phone' && step === 2"
          :height="$vuetify.breakpoint.smAndDown ? 48 : 60"
          :loading="loading"
          :text="$t('login.button')"
          class="login-process-btn"
          size="x-large"
          @onClick="submitPhoneAuth"
        />
      </div>
      <div v-if="step === 2 && method === 'phone'" class="phone-number-actions">
        <div v-if="resendTimerSeconds !== 0" class="timer">
          <p><span>Resend code in</span> {{ resendTimerSeconds }}</p>
        </div>
        <a v-else class="resend" href="#" @click="onResendCodeClick($event)">Did not receive code?</a>
        <p class="edit-number" @click="onEditNumberClick">Edit phone number</p>
      </div>
      <div id="sign-in-recaptcha"></div>
      <div
        v-if="this.step === 2"
        class="login_remember-me d-flex flex-row full-width align-center justify-space-between mt-3 mb-3"
      >
        <v-checkbox
          v-model="rememberMe"
          :hide-details="true"
          :label="$t('login.rememberMe')"
          class="login_checkbox"
          dense
        />
        <span class="login_link" @click="passwordReset">{{ $t("login.resetPasswordLink") }}</span>
      </div>
      <span class="login_link mt-8" @click="passwordReset">{{ $t("login.resetPasswordLink") }}</span>
    </div>
    <Snackbar v-model="snackbar" :text="error" :timeout="4000" type="error" @toggleSnackbar="toggleSnackbar" />
  </div>
</template>
<script>
import * as firebase from "firebase/app";
import { mapActions as mapPiniaActions } from "pinia";
import { mapState as mapPiniaState } from "pinia";
import { mapActions } from "pinia";
import { email, required } from "vuelidate/lib/validators";

import PasswordInput from "@/components/shared/PasswordInput";
import PhoneInput from "@/components/shared/PhoneInput";
import Snackbar from "@/components/shared/Snackbar";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { usePatientStore } from "@/pinia-store/patient";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";
import { isValidEmail, isValidPhoneNumber } from "@/utils/validations";

const codeLength = (value) => {
  return value.length === 6;
};
export default {
  name: "Login",
  components: {
    PhoneInput,
    BaseInput,
    PasswordInput,
    PrimaryButton,
    Snackbar,
  },
  props: ["renderRole"],
  validations() {
    const validations = { email: {}, phoneNumber: {}, smsCodeErrors: {} };
    if (this.method === "phone") {
      validations.phoneNumber = { required };
    }
    if (this.method === "email") {
      validations.email = { required, email };
      validations.password = { required };
    }
    if (this.step === 2 && this.method === "phone") {
      validations.smsCode = { required, codeLength };
    }

    return validations;
  },

  data: () => ({
    step: 1,
    emailOrPhone: "",
    email: "",
    phoneNumber: "",
    password: "",
    method: "email",
    showSmsCode: false,
    rememberMe: true,
    snackbar: false,
    smsCode: "",
    error: "",
    loading: false,
    confirmationResult: "",
    resendTimerSeconds: 0,
    resendTimer: null,
  }),
  computed: {
    ...mapPiniaState(useAuthStore, ["role"]),
    cleanPhone() {
      if (!this.phoneNumber) return "";
      return this.phoneNumber.trim().replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("-", "");
    },
    isNextEnabled() {
      if (this.method === "email" && isValidEmail(this.email)) return true;
      if (this.method === "phone" && this.phoneNumber && isValidPhoneNumber(this.cleanPhone)) return true;

      return false;
    },
    emailErrors() {
      const errors = [];
      if (this.method !== "email") return [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (this.method !== "phone") return [];

      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
    smsCodeErrors() {
      const errors = [];
      if (!this.$v.smsCodeErrors.$dirty) return errors;
      !this.$v.smsCode.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.code") }));
      !this.$v.smsCode.codeLength &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.code") }));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (this.step === 1 || this.method !== "email") return [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.password") }));
      return errors;
    },
  },
  methods: {
    ...mapPiniaActions(useNotificationsStore, { cleanUpNotifications: "cleanUp" }),
    ...mapPiniaActions(useAuthStore, ["submitLogin", "resetPassword", "actualizeUserInfo", "updateTimeZone"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useProfileSettingsStore, ["getPatientSettings"]),
    async passwordReset() {
      await this.$router.push({ name: routesEnum.requestResetPassword });
    },
    onCodeInput(element) {
      element.target.value = element.target.value.replace(/[^0-9]/g, "");
      const index = +element.target.getAttribute("index");
      if (element.inputType === "deleteContentBackward") {
        if (index !== 0) {
          document.querySelector(`.code-cell[index='${index - 1}']`).focus();
        }
      }
      if (index !== 5 && element.inputType !== "deleteContentBackward") {
        document.querySelector(`.code-cell[index='${index + 1}']`).focus();
      }
    },
    onResendCodeClick(event) {
      event.preventDefault();
      this.submitFirstStep();
    },
    onEditNumberClick() {
      this.resendTimerSeconds = 0;
      clearInterval(this.resendTimer);
      this.resendTimer = null;
      this.step = 1;
    },
    phoneNumberChange(phoneNumber) {
      this.phoneNumber = phoneNumber;
      this.method = "phone";
    },
    onEmailChange(value) {
      this.email = value;
      this.method = "email";
    },
    toggleSnackbar() {
      this.snackbar = !this.snackbar;
    },
    onInputChange(value, fieldName) {
      this[fieldName] = value;
    },

    submitFirstStep() {
      this.loading = true;

      if (this.method === "phone") {
        this.sendCode();
        this.resendTimerSeconds = 60;
        this.resendTimer = setInterval(() => {
          if (this.resendTimerSeconds <= 0) {
            clearInterval(this.resendTimer);
            this.resendTimer = null;
          } else {
            this.resendTimerSeconds = this.resendTimerSeconds - 1;
          }
        }, 1000);
      }
      this.step = 2;
      this.loading = false;
    },
    async sendCode() {
      if (isValidPhoneNumber(this.phoneNumber)) {
        firebase
          .auth()
          .signInWithPhoneNumber(this.cleanPhone, window.recaptchaVerifier)
          .then((confirmationResult) => {
            this.confirmationResult = confirmationResult;
            this.showSmsCode = true;
            this.loading = false;
            this.step = 2;
          })
          .catch(() => {
            this.error = "Error on sending message";
            this.snackbar = true;
          });
      }
    },
    async submitPhoneAuth() {
      this.loading = true;
      this.smsCode = "";
      const codeInputs = document.querySelectorAll(".code-cell");
      codeInputs.forEach((element) => {
        this.smsCode += element.value;
      });
      try {
        const credential = firebase.auth.PhoneAuthProvider.credential(
          this.confirmationResult.verificationId,
          this.smsCode,
        );
        await firebase.auth().signInWithCredential(credential);
        await this.actualizeUserInfo();
        await this.updateTimeZone();
        await this.managePostActions();
      } catch (err) {
        this.snackbar = true;
        this.error = err.message;
      }
      this.loading = false;
    },
    async submitEmailAuth() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false;
        return;
      }
      try {
        const body = {
          password: this.password,
          rememberMe: this.rememberMe,
        };

        if (isValidEmail(this.email)) body.email = this.email;
        await this.submitLogin(body);
        await this.managePostActions();
      } catch (err) {
        this.snackbar = true;
        this.error = err.message;
      }
      this.loading = false;
    },
    async managePostActions() {
      const redirect = this.$route.query.redirect;
      if (redirect) {
        await this.$router.push({ path: redirect });
      } else if (this.role === RolesEnum.Patient) {
        this.getPatientProfile(this.uid)
          .then()
          .catch((e) => console.error(`Failed to fetch patient data. ${e}`));
        this.getPatientSettings()
          .then((settings) => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (settings.timeZone !== timeZone && !settings.isAutoTimeZone) {
              snackBarEventBus.$emit(snackBarEventName, {
                message: `Your local time zone does not match your current profile setting. Operation with dates will use your profile settings (${settings.timeZone}). In order to change your current time zone please proceed to profile settings.`,
                type: "info",
              });
            }
          })
          .catch((e) => console.error(`Failed to fetch patient settings data. ${e}`));

        await this.$router.push({ path: "/patient/visits/upcoming" });
      } else {
        if (this.role === RolesEnum.Practitioner) await this.$router.push({ name: routesEnum.practitionerSchedule });
        else await this.$router.push({ path: `/${this.role}` });
      }
    },
  },
  mounted() {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "ACTION", action: "LOGOUT" }));
    }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-recaptcha", {
      size: "invisible",
    });
    this.cleanUpNotifications();
  },
};
</script>

<style lang="scss">
.login_methods {
  width: 100%;
  margin-bottom: 30px;

  &__tab {
    width: 100%;
    text-transform: capitalize;
    font-size: 14px;
  }
}

.code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;

  &-cell {
    text-align: center;
    border: 1px solid #eeeff7;
    box-sizing: border-box;
    border-radius: 4px;
    height: 60px;
    width: 60px;
    font-size: 36px;
    font-weight: 500;

    &:focus {
      outline: 1px solid $primary;
    }
  }
}

.phone-number-actions {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: $primary;
    text-decoration: none;
  }

  .timer {
    span {
      color: #33343e;
      opacity: 0.4;
    }
  }

  .edit-number {
    color: #33343e;
    opacity: 0.4;
    cursor: pointer;
  }
}

.login_checkbox {
  margin-top: 0 !important;
}

.login_divider {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(51, 52, 62, 0.5);
  padding-bottom: 20px;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid rgba(51, 52, 62, 0.12);
    margin: auto;
  }

  &:before {
    margin-right: 15px;
  }

  &:after {
    margin-left: 15px;
  }
}

.login {
  &_card {
    text-align: center;
    width: 440px;
    margin: 10px auto;

    .v-input:not(.login_checkbox) {
      margin-bottom: 20px;
    }

    @media (max-width: 767.98px) {
      margin: 10px 20px;
      width: 100%;
      max-width: 375px;
      align-self: center;
    }

    .login-process-btn {
      margin-top: 30px;
    }
  }

  &_remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_checkbox {
    cursor: pointer;

    label {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: $primaryblack;

      opacity: 0.36;
    }
  }

  &_link {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: right;

    color: $primaryblack;

    opacity: 0.4;
  }
}

#sign-in-recaptcha {
  width: 100%;
  flex-basis: 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
